<style lang="scss" scoped>
.oprate-btn {
    cursor: pointer;
}
</style>
<template>
    <div class="account-table u-flex">
        <el-table :data="priceData">
            <el-table-column  prop="createTime" label="创建时间">
                <template slot-scope="scope">
                    {{ scope.row.createTime | myTimeToLocal }}
                </template>
            </el-table-column>
            <el-table-column label="机房名称">
                <span style="color: #169bd5">{{roomName}}</span>
            </el-table-column>
            <el-table-column  prop="startTime" label="生效时间">
                <template slot-scope="scope">
                    {{ scope.row.startTime | myTimeToLocal }}
                </template>
            </el-table-column>
            <el-table-column  prop="endTime" label="失效时间">
                <template slot-scope="scope">
                    {{ scope.row.endTime | myTimeToLocal }}
                </template>
            </el-table-column>
            <el-table-column  prop="price" label="价格（元/Mbps/日）">
                <template slot-scope="scope">
                    <span>{{ scope.row.price }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="remark" label="备注" />
            <el-table-column  prop="status" label="状态" />
            <el-table-column  label="操作" v-if="isAuth">
                <template slot-scope="scope">
                    <span v-if="(scope.row.status === '待生效')" class="oprate-btn" @click="editPrice(scope.row)">
                        <i class="el-icon-edit-outline"></i>
                        <span style="margin-left: 5px">编辑</span>
                    </span>
                    <span v-if="scope.row.status === '待生效'" style="margin-left: 8px" class="oprate-btn" @click="deletePrice(scope.row.id)">
                        <i class="el-icon-delete"></i>
                        <span style="margin-left: 5px">删除</span>
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <!-- <el-pagination
        class="fr pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination> -->
    </div>
</template>
<script>
import room from "/src/api/room.js";
export default {
    name: 'RoomInfo',
    props: {
        isAuth: {
            type: [Boolean],
            default: false,
        }
    },
  data() {
    return {
        priceData: [],
        roomID: null,
        roomName: '',
        params: {
            page: 1,
            size: 20,
            manufacturerId: null,
        },
        pagination: {
            total: 0,
            currentPage: 1,
            pageSize: 20,
            pageSizes: [10, 15, 20],
        },
    }
  },
  watch: {
  },
  filters: {
    myTimeToLocal: function (inputTime) {
        if (!inputTime && typeof inputTime !== "number") {
            return "";
        }
        var localTime = "";
        inputTime = new Date(inputTime).getTime();
        const offset = new Date().getTimezoneOffset();
        localTime = new Date(inputTime - offset * 60000).toISOString();
        localTime = localTime.substr(0, localTime.lastIndexOf("."));
        localTime = localTime.replace("T", " ").slice(0,16);
        return localTime;
    }
},
  mounted() {
    this.$EventBus.$on('updateRowInfo', (data) => {
        this.roomID = data.id
        this.roomName = data.name
        this.getPrice()
    });
  },
  methods: {
    handleSizeChange(val) {
      this.params.size = val;
      this.getPrice(this.params);
    },

    handleCurrentChange(val) {
      this.params.page = val;
      this.getPrice(this.params);
    },
    getPrice() {
        room.getPrice(this.params, this.roomID).then((item) => {
        this.priceData = item.confs;
        this.pagination.total = item.count;
      });
    },
    editPrice(row) {
        this.$EventBus.$emit('updatePriceDialog', row);
    },
    deletePrice(priceID) {
        this.$confirm('确认是否继续删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            room.deletePrice(priceID).then((data) => {
                if (data) {
                    this.$message("删除成功！");
                    this.getPrice()
                }
            });
        }).catch(() => {
            console.log('已取消删除')         
        });
    }
  }
}
</script>
