const CountryProCity = [
    {
        "countries": {
          "中国": {
            "isps": ["电信", "联通", "教育网", "鹏博士", "Microsoft", "Apple", "移动", "铁通", "科技网", "Baidu", "Amazon", "华数", "Google", "Yahoo"],
            "provinces": {
              "福建省": ["电信", "铁通", "联通", "鹏博士", "教育网", "移动"],
              "广东省": ["电信", "教育网", "鹏博士", "联通", "铁通", "科技网", "移动"],
              "北京市": ["联通", "电信", "鹏博士", "铁通", "教育网", "科技网", "移动"],
              "台湾省": ["Amazon", "Google", "Yahoo", "电信", "联通"],
              "香港特别行政区": ["Microsoft", "Apple", "移动", "联通", "电信", "Baidu", "Amazon", "Google", "Yahoo"],
              "吉林省": ["电信", "铁通", "教育网", "鹏博士", "联通", "移动", "科技网"],
              "天津市": ["电信", "铁通", "联通", "鹏博士", "教育网", "移动"],
              "辽宁省": ["电信", "铁通", "移动", "联通", "鹏博士", "教育网", "科技网"],
              "上海市": ["电信", "联通", "铁通", "鹏博士", "教育网", "科技网", "移动"],
              "内蒙古自治区": ["联通", "电信", "铁通", "教育网", "移动"],
              "贵州省": ["电信", "铁通", "教育网", "鹏博士", "联通", "移动"],
              "宁夏回族自治区": ["电信", "铁通", "联通", "教育网", "移动"],
              "江苏省": ["教育网", "铁通", "联通", "电信", "鹏博士", "移动", "科技网"],
              "安徽省": ["教育网", "联通", "电信", "铁通", "鹏博士", "移动", "科技网"],
              "山东省": ["教育网", "联通", "铁通", "电信", "鹏博士", "移动", "科技网"],
              "黑龙江省": ["联通", "铁通", "电信", "教育网", "鹏博士", "移动"],
              "山西省": ["电信", "铁通", "教育网", "鹏博士", "联通", "移动", "科技网"],
              "陕西省": ["电信", "鹏博士", "铁通", "教育网", "联通", "移动", "科技网"],
              "广西省": ["教育网", "鹏博士", "铁通", "电信", "联通", "移动"],
              "河南省": ["电信", "联通", "铁通", "移动", "教育网", "鹏博士"],
              "重庆市": ["电信", "联通", "铁通", "教育网", "鹏博士", "移动"],
              "四川省": ["鹏博士", "铁通", "教育网", "联通", "电信", "移动", "科技网"],
              "河北省": ["鹏博士", "电信", "铁通", "教育网", "联通", "移动", "科技网"],
              "浙江省": ["鹏博士", "电信", "铁通", "移动", "教育网", "联通", "华数", "科技网"],
              "云南省": ["联通", "铁通", "电信", "教育网", "鹏博士", "移动", "科技网"],
              "澳门特别行政区": ["教育网", "联通", "电信"],
              "湖北省": ["电信", "铁通", "鹏博士", "教育网", "联通", "移动", "科技网"],
              "西藏自治区": ["联通", "移动", "铁通", "电信", "教育网"],
              "甘肃省": ["电信", "铁通", "教育网", "联通", "移动", "科技网"],
              "海南省": ["电信", "铁通", "联通", "教育网", "移动", "鹏博士"],
              "新疆维吾尔自治区": ["电信", "铁通", "教育网", "联通", "移动", "科技网"],
              "青海省": ["电信", "铁通", "联通", "教育网", "移动", "科技网"],
              "江西省": ["铁通", "移动", "教育网", "联通", "鹏博士", "电信"],
              "湖南省": ["铁通", "联通", "鹏博士", "教育网", "电信", "移动", "科技网"],
            }
          },
          "澳大利亚": {
            "isps": ["Microsoft", "Apple", "Amazon", "Google", "电信"]
          },
          "日本": {
            "isps": ["Microsoft", "Apple", "Amazon", "电信", "Google", "Baidu", "联通"]
          },
          "泰国": {},
          "印度": {
            "isps": ["Microsoft", "Amazon", "Google"]
          },
          "马来西亚": {
            "isps": ["Google", "电信"]
          },
          "安哥拉": {},
          "韩国": {
            "isps": ["Apple", "Amazon", "Microsoft", "Yahoo", "联通"]
          },
          "俄罗斯": {
            "isps": ["Microsoft", "Google"]
          },
          "新加坡": {
            "isps": ["Microsoft", "Apple", "Amazon", "电信", "Google", "移动"]
          },
          "菲律宾": {
            "isps": ["Amazon", "Google"]
          },
          "越南": {
            "isps": ["电信"]
          },
          "法国": {
            "isps": ["Amazon", "Microsoft", "Google"]
          },
          "英国": {
            "isps": ["Apple", "Microsoft", "Amazon", "电信", "Google", "移动"]
          },
          "荷兰": {
            "isps": ["Microsoft", "Apple", "Amazon", "Google"]
          },
          "德国": {
            "isps": ["Apple", "Microsoft", "Amazon", "Google", "电信", "联通", "移动"]
          },
          "西班牙": {
            "isps": ["Amazon", "Google"]
          },
          "奥地利": {
            "isps": ["Microsoft"]
          },
          "丹麦": {},
          "瑞典": {
            "isps": ["Apple", "Amazon", "Microsoft", "Google"]
          },
          "意大利": {
            "isps": ["Amazon", "Google"]
          },
          "美国": {
            "isps": ["Amazon", "Google", "Microsoft", "Apple", "电信", "联通", "移动"]
          },
          "芬兰": {
            "isps": ["Google"]
          },
          "希腊": {
            "isps": ["Google"]
          },
          "爱尔兰": {
            "isps": ["Microsoft", "Apple", "Amazon", "Google"]
          },
          "保加利亚": {
            "isps": ["Google"]
          },
          "阿联酋": {
            "isps": ["Google"]
          },
          "南非": {
            "isps": ["Google"]
          },
          "波兰": {
            "isps": ["Amazon", "Microsoft", "Google"]
          },
          "比利时": {
            "isps": ["Google"]
          },
          "以色列": {
            "isps": ["Apple", "Google"]
          },
          "卡塔尔": {},
          "乌克兰": {
            "isps": ["Google"]
          },
          "哈萨克斯坦": {
            "isps": ["联通"]
          },
          "葡萄牙": {
            "isps": ["Google", "Microsoft"]
          },
          "沙特阿拉伯": {},
          "伊朗": {},
          "挪威": {
            "isps": ["Google"]
          },
          "加拿大": {
            "isps": ["Google", "Microsoft"]
          },
          "叙利亚": {},
          "科威特": {},
          "巴林": {},
          "黎巴嫩": {},
          "阿曼": {},
          "约旦": {},
          "捷克": {
            "isps": ["Google"]
          },
          "立陶宛": {},
          "瑞士": {
            "isps": ["Microsoft", "Google"]
          },
          "伊拉克": {},
          "土耳其": {},
          "罗马尼亚": {
            "isps": ["Google"]
          },
          "印度尼西亚": {},
          "匈牙利": {
            "isps": ["Google"]
          },
          "格鲁吉亚": {},
          "阿塞拜疆": {},
          "布隆迪": {},
          "津巴布韦": {},
          "赞比亚": {},
          "刚果(金)": {},
          "巴勒斯坦": {},
          "斯洛伐克": {},
          "塞尔维亚": {},
          "冰岛": {},
          "斯洛文尼亚": {},
          "摩尔多瓦": {},
          "马其顿": {},
          "列支敦士登": {},
          "泽西岛": {},
          "克罗地亚": {},
          "根西岛": {},
          "波斯尼亚和黑塞哥维那": {},
          "爱沙尼亚": {
            "isps": ["Microsoft"]
          },
          "拉脱维亚": {},
          "智利": {
            "isps": ["Google"]
          },
          "秘鲁": {},
          "巴西": {
            "isps": ["Microsoft", "Amazon", "Google"]
          },
          "吉尔吉斯斯坦": {},
          "留尼汪岛": {},
          "马恩岛": {},
          "直布罗陀": {},
          "利比亚": {},
          "亚美尼亚": {},
          "也门": {},
          "白俄罗斯": {},
          "瓜德罗普": {},
          "卢森堡": {
            "isps": ["Amazon"]
          },
          "马提尼克岛": {},
          "圭亚那": {},
          "科索沃": {},
          "关岛": {
            "isps": ["Microsoft"]
          },
          "多米尼加": {},
          "墨西哥": {
            "isps": ["Google"]
          },
          "委内瑞拉": {},
          "波多黎各": {},
          "格林纳达": {},
          "蒙古": {
            "isps": ["电信"]
          },
          "新西兰": {
            "isps": ["Microsoft", "Google"]
          },
          "尼泊尔": {},
          "巴布亚新几内亚": {},
          "巴基斯坦": {},
          "哥伦比亚": {},
          "阿根廷": {
            "isps": ["Google"]
          },
          "斯里兰卡": {},
          "埃及": {},
          "厄瓜多尔": {},
          "哥斯达黎加": {},
          "乌拉圭": {},
          "巴巴多斯": {},
          "巴哈马": {},
          "圣卢西亚": {},
          "孟加拉": {},
          "托克劳群岛": {},
          "柬埔寨": {},
          "马尔代夫": {},
          "阿富汗": {},
          "新喀里多尼亚": {},
          "斐济": {},
          "瓦利斯和富图纳群岛": {},
          "尼日利亚": {
            "isps": ["Google"]
          },
          "阿尔巴尼亚": {},
          "乌兹别克斯坦": {},
          "塞浦路斯": {},
          "圣马力诺": {},
          "黑山": {},
          "塔吉克斯坦": {},
          "马耳他": {},
          "百慕大": {},
          "圣文森特和格林纳丁斯": {},
          "牙买加": {},
          "多哥": {},
          "危地马拉": {},
          "玻利维亚": {},
          "几内亚": {},
          "苏里南": {},
          "利比里亚": {},
          "肯尼亚": {
            "isps": ["Google"]
          },
          "加纳": {},
          "坦桑尼亚": {},
          "塞内加尔": {},
          "马达加斯加": {},
          "纳米比亚": {},
          "科特迪瓦": {},
          "苏丹": {},
          "喀麦隆": {},
          "马拉维": {},
          "加蓬": {},
          "马里": {},
          "贝宁": {},
          "乍得": {},
          "博茨瓦纳": {},
          "佛得角": {},
          "卢旺达": {},
          "刚果(布)": {},
          "乌干达": {
            "isps": ["Google"]
          },
          "莫桑比克": {},
          "冈比亚": {},
          "莱索托": {},
          "毛里求斯": {},
          "阿尔及利亚": {},
          "斯威士兰": {},
          "布基纳法索": {},
          "塞拉利昂": {},
          "索马里": {},
          "尼日尔": {},
          "中非": {},
          "南苏丹": {},
          "赤道几内亚": {},
          "塞舌尔": {},
          "吉布提": {},
          "摩洛哥": {},
          "毛里塔尼亚": {},
          "科摩罗": {},
          "英属印度洋领地": {},
          "开曼群岛": {},
          "突尼斯": {},
          "马约特": {},
          "老挝": {},
          "缅甸": {},
          "文莱": {},
          "瑙鲁": {},
          "瓦努阿图": {},
          "不丹": {},
          "密克罗尼西亚联邦": {},
          "法属波利尼西亚": {},
          "东帝汶": {},
          "萨摩亚": {},
          "汤加": {},
          "北马里亚纳群岛": {},
          "格陵兰": {},
          "英属维尔京群岛": {},
          "法罗群岛": {},
          "纽埃岛": {},
          "福克兰群岛": {},
          "特克斯和凯科斯群岛": {},
          "洪都拉斯": {},
          "库拉索": {},
          "荷兰加勒比": {},
          "特立尼达和多巴哥": {},
          "马绍尔群岛": {},
          "库克群岛": {},
          "巴拿马": {},
          "法属圣马丁": {},
          "美属维尔京群岛": {},
          "美属萨摩亚": {},
          "萨尔瓦多": {},
          "荷属圣马丁": {},
          "阿鲁巴": {},
          "巴拉圭": {},
          "多米尼克": {},
          "安提瓜和巴布达": {},
          "安圭拉": {},
          "圣基茨和尼维斯": {},
          "圣皮埃尔和密克隆群岛": {},
          "土库曼斯坦": {},
          "奥兰群岛": {},
          "摩纳哥": {},
          "法属圭亚那": {},
          "安道尔": {},
          "南极洲": {},
          "梵蒂冈": {},
          "海地": {},
          "所罗门群岛": {},
          "基里巴斯": {},
          "帕劳": {},
          "诺福克岛": {},
          "伯利兹": {},
          "尼加拉瓜": {},
          "古巴": {},
          "圣多美和普林西比": {},
          "几内亚比绍": {},
          "朝鲜": {},
          "厄立特里亚": {},
          "埃塞俄比亚": {},
          "蒙塞拉特岛": {},
          "图瓦卢": {},
          "圣诞岛": {},
          "亚洲": {
            "isps": ["电信", "移动", "联通", "科技网", "教育网", "鹏博士", "Baidu", "Yahoo", "Google", "Microsoft", "铁通", "华数", "Amazon", "Apple"]
          },
          "北美洲": {
            "isps": ["Amazon", "移动", "联通", "Microsoft", "Google", "电信", "Apple"]
          },
          "欧洲": {
            "isps": ["Microsoft", "移动", "联通", "Amazon", "Google", "电信", "Apple"]
          },
          "大洋洲": {
            "isps": ["Microsoft", "电信", "Google", "Amazon", "Apple"]
          },
          "南美洲": {
            "isps": ["Microsoft", "Google", "Amazon"]
          },
          "非洲": {
            "isps": ["Google"]
          },
          "海外": {}
        },
        "isps": ["电信", "联通", "教育网", "鹏博士", "Amazon", "Google", "Microsoft", "Apple", "移动", "铁通", "科技网", "Baidu", "华数", "Yahoo"]
      }
]
export default CountryProCity
    
  
  