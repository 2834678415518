<template>
  <div class="position container">
    <div class="title u-flex" v-if="isAuth && idcOptionData.length">
      <div class="head">IDC厂商</div>
      <SelectPagination
        :selectValue.sync="params.manufacturerId"
        :option-data="idcOptionData"
        :page-info="pageInfo"
        @selectPageChange="idcPageChange($event)"
        select-type="selectIdc"
        placeholder="请选择 IDC 厂商"
        size="small"
      ></SelectPagination>
    </div>
    <section class="operate">
      <el-row>
      <el-col :span="22" class="u-flex condition">
        <div class="u-flex mgr operate-item">
            <label>国家</label>
            <el-select
            placeholder="请选择国家"
            v-model="params.region"
            size="small"
            clearable
            >
            <el-option
                v-for="(item, index) in regionsList"
                :key="index"
                :value="item"
                :label="item.value"
            ></el-option>
            </el-select>
        </div>
        <div class="u-flex mgr operate-item">
            <label>省份</label>
            <el-select
            placeholder="请选择省份"
            v-model="params.province"
            size="small"
            clearable
            >
            <el-option
                v-for="(item, index) in provincesList"
                :key="index"
                :value="item"
                :label="item.value"
            ></el-option>
            </el-select>
        </div>
        <div class="u-flex mgr operate-item">
            <label>运营商</label>
            <el-select
            placeholder="请选择运营商"
            v-model="params.isp"
            size="small"
            clearable
            >
            <el-option
                v-for="(item, index) in ispsList"
                :key="index"
                :label="item.value"
                :value="item"
            ></el-option>
            </el-select>
        </div>
        <div class="u-flex mgr operate-item">
            <label>状态</label>
            <el-select
            placeholder="请选择机房状态"
            v-model="params.status"
            size="small"
            clearable
            >
            <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.value"
                :value="item"
            ></el-option>
            </el-select>
        </div>
        <div class="u-flex mgr operate-item">
            <label>机房名</label>
            <el-select
            placeholder="请选择机房"
            v-model="params.name"
            size="small"
            width="80"
            clearable
            filterable
            >
            <el-option
                v-for="(item, index) in roomOptionData"
                :key="index"
                :value="item"
            ></el-option>
            </el-select>
        </div>
      </el-col>
      <el-col :span="2">
      <div class="createBtn">
        <el-button type="primary" @click="addRoom()">创建</el-button>
      </div>
      </el-col>
      </el-row>
    </section>

    <div class="account-table u-flex">
      <el-table :data="roomData">
        <el-table-column  prop="name" label="名称">
          <template slot-scope="scope">
            <p style="color: #169bd5">{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column  prop="status" label="状态" width="120">
          <template slot-scope="scope">
            {{ scope.row.status === 1 ? '服务中' : '已关闭' }}
          </template>
        </el-table-column>
        <el-table-column  prop="province" label="省份" width="120"> </el-table-column>
        <el-table-column  prop="isp" label="运营商" width="120"> </el-table-column>
        <el-table-column  prop="bandwidth" label="可用带宽" width="120">
          <template slot-scope="scoped">
            <span>{{ scoped.row.bandwidth }}GB</span>
          </template>
        </el-table-column>
        <el-table-column  prop="price" label="单价" width="180">
          <template slot-scope="scoped">
            <span>{{ scoped.row.price.toFixed(2) }} 元/Mbps/日</span>
          </template>
        </el-table-column>
        <el-table-column  label="操作" width="150">
          <template slot-scope="scope">
            <span class="oprate-btn" @click="edit(scope.row)">
              <i class="el-icon-edit-outline"></i>
              <span style="margin-left: 5px">编辑</span>
            </span>
            <span v-if="isAuth" style="margin-left: 8px" class="oprate-btn" @click="changeStatus(scope.row)">
              <i v-if="scope.row.status === 1" class="el-icon-remove-outline"></i>
              <i v-else class="el-icon-switch-button"></i>
              <span style="margin-left: 5px">{{scope.row.status === 1 ? '关闭' : '开启'}}</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="fr pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="[10, 15, 20]"
      :page-size="pagination.pageSize"
      :total="pagination.total"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <RoomModal :createDialog.sync="createDialog" :isAuth="isAuth" :isEdite="isEdite" :manufacturerId="params.manufacturerId"/>
  </div>
</template>

<script>
import room from "/src/api/room.js";
import users from "/src/api/user.js";
import SelectPagination from "@/components/SelectPagination";
import RoomModal from "./RoomModal";
// import _ from "lodash";

export default {
  components: { SelectPagination, RoomModal },
  data() {
    return {
      roomData: [],
      regionsList: [],
      provincesList: [],
      ispsList: [],
      statusList: [
        '服务中',
        '已关闭',
      ],
      statusMap: {
        '服务中': 1,
        '已关闭': 2,
      },
      roomOptionData: [],
      idcOptionData: [],
      createDialog: false,
      isEdite: false,
      isAuth: false,
        pageInfo: {
            page: 1,
            size: 10,
        },
      params: {
        page: 1,
        size: 20,
        manufacturerId: null,
      },
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 20,
      },
    };
  },
  watch: {
    params: {
      handler: function () {
        this.pagination = Object.assign({}, this.pagination, {currentPage: 1})
        this.getRoom();
      },
      deep: true,
    },
  },

  mounted() {
    this.$EventBus.$on('updateCreateDialog', (data) => {
        this.createDialog = data
    });
    this.$EventBus.$on('updateRoom', (data) => {
        if (data) {
            this.getRoom()
            return
        }
    });
    this.isAuth = JSON.parse(localStorage.getItem("user")).roles.includes("admin");
    this.getRoom();
    this.idcPageChange();
  },
  methods: {
    getRoom() {
      const params = Object.assign({}, this.params, {page: this.pagination.currentPage, size: this.pagination.pageSize});
      if (params.status) {
        params.status = this.statusMap[params.status];
      } else {
        delete params.status;
      }
      room.getRoom(params).then((item) => {
        this.roomData = item.rooms;
        this.pagination.total = item.count;
      });
      this.getSelect();
    },
    filterRoomName(name) {
      if (name !== '') {
         this.params.name = name;
      } else {
        this.params.name = '';
      }
    },
    idcPageChange(page) {
       // 获取idc厂商list
      let idcParams = { page: page || this.pageInfo.page, size: this.pageInfo.size };
      users.getUserAccount(idcParams).then((data) => {
        this.idcOptionData = data.list || [];
        this.pageInfo.total = data.count;
      });
    },

    getSelect() {
      // 获取首页 select 的数组
      room.getSelect({ id: this.params.manufacturerId }).then((data) => {
        this.ispsList = data.isps;
        this.regionsList = data.regions;
        this.provincesList = data.provinces.filter(Boolean);
        this.roomOptionData = data.names;
      });
    },
    handleSizeChange(val) {
        this.pagination.pageSize = val
    },
    handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getRoom();
    },

    addRoom() {
      // 创建按钮
      this.isEdite = false;
      if (this.isAuth === true && this.params.manufacturerId === null) {
        this.$message("请选择厂商！");
        this.createDialog = false;
      } else {
        this.createDialog = true;
      }
    },
    edit(row) {
      // 编辑操作
      this.isEdite = true;
      this.createDialog = true;
      room.getRoomId(this.params, row.id).then((item) => {
        this.$EventBus.$emit('updateRowInfo', item);
      });
    },
    changeStatus(row) {
        this.$confirm(`<p>确定 ${row.status === 1 ? '关闭' : '开启'} 机房服务？</p><p class="red">${row.status === 1 ? '此处关闭，不会关闭正常业务，仅用于本控制台展示，实际需要人工线下操作！' : ''}</p>`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
        }).then(() => {
            const status = row.status === 1 ? 2 : 1
            room.changeStatus({status: status}, row.id).then(() => {
                this.$message({
                    message: '操作成功！',
                    type: 'success'
                });
                this.getRoom()
            })
        }).catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '已取消删除'
        //   });          
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.position {
  overflow: scroll;
  .head {
    margin-right: 10px;
  }

label {
    margin-right: 5px

}
  .operate {
    margin: 10px 10px 40px 10px;
    justify-content: space-between
  }
  .operate-item {
    margin-top: 10px;
  }
  .condition {
    flex-wrap: wrap;
  }
  .mgr {
    margin-right: 15px;
  }
  .createBtn {
    margin-top: 10px;
    float: right;
  }
  .oprate-btn:hover {
    cursor: pointer;
  }
  .pagination {
    margin: 40px 70px 0 0;
  }
  .ip-flex {
    display: flex;
    align-items: center;
  }
}
</style>
