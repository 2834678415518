<style lang="scss" scoped>
.isp-config {
    width: 108px;
    text-align: right;
    padding-right: 12px;
    box-sizing: border-box;;
    color: #606266;
    font-size: 14px;
    font-weight: 700;
  }
.machine {
    width: 528px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    padding: 10px 40px;
    border-radius: 4px;
    color: #606266;
    font-size: 14px;
}

.w200 {
    width: 100%;
  }
.ip-flex {
    display: flex;
    align-items: center;
  }

  .click {
    display: flex;
    justify-content: flex-end;
  }
</style>
<style lang="scss">
.fillable-label {
    .el-form-item__label {
        padding-left: 10px
    }
}
.inline-form {
    display: inline-block;
    .el-form-item__content {
        display: inline-block;
        margin-left: 0px!important;
    }
}
.el-drawer__header {
    margin-bottom: 10px;
}
</style>
<template>
    <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="108px"
        :rules="rules"
    >
        <el-row>
            <el-col :span="6">
                <el-form-item label="国家/地区" prop="region">
                <el-select
                    size="small"
                    clearable
                    class="w200"
                    v-model="form.region"
                    value-key="index"
                >
                    <el-option
                    v-for="(item, index) in countryFormList"
                    :key="index"
                    :value="item"
                    :label="item"
                    ></el-option>
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="省份" class="fillable-label" prop="province">
                <el-select
                    size="small"
                    clearable
                    class="w200"
                    value-key="index"
                    v-model="form.province"
                >
                    <el-option
                    v-for="(item, index) in provincesFormList"
                    :key="index"
                    :value="item"
                    :label="item"
                    ></el-option>
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="城市" class="fillable-label" prop="city">
                <el-select size="small" clearable class="w200" v-model="form.city">
                    <el-option
                    v-for="(item, index) in cityFormList"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                    ></el-option>
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="ISP" prop="isp">
                <el-select size="small" clearable class="w200" v-model="form.isp">
                    <el-option
                    v-for="(item, index) in ispsFormList"
                    :key="index"
                    :value="item"
                    :label="item"
                    ></el-option>
                </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <el-form-item label="机房名称" prop="name">
                <el-input size="small" class="w200" v-model="form.name"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="机柜" prop="cabinet">
                <el-input size="small" class="w200" v-model="form.cabinet"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="地址" prop="address">
                <el-input size="small" class="w200" v-model="form.address"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="可用带宽" prop="bandwidth">
                <el-input
                    size="small"
                    class="w200"
                    v-model.number="form.bandwidth"
                >
                    <template slot="append">GB</template>
                </el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6" v-if="!isEdite">
                <el-form-item label="机房单价" prop="price">
                <el-input
                    size="small"
                    class="w200"
                    :disabled="!isAuth"
                    v-model.number="form.price"
                >
                    <template slot="append">元/Mbps/日</template>
                </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="备注" class="fillable-label" prop="remark">
                <el-input
                    size="small"
                    class="w200"
                    v-model="form.remark"
                ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24" style="display: flex;margin-bottom: 20px;">
                <div class="isp-config">机器 IP</div>
                <div class="machine">
                    <section class="ip-flex">
                        <p style="width: 156px">IP</p>
                        <p style="width: 142px">网口</p>
                        <p style="width: 115px">数量</p>
                        <i class="el-icon-circle-plus oprate-btn" @click="addBtn()"></i>
                    </section>
                    <div v-for="(config, index) in form.room_ips" :key="index">
                        <el-form-item class="inline-form" style="width: 140px"
                        :prop="'room_ips[' + index + '].ip'"
                        :rules="{ validator: validateIP, trigger: ['blur', 'change'] }">
                            <el-input v-model="config.ip" placeholder="请输入 IP 地址" clearable size="small"></el-input>
                        </el-form-item>
                        <el-form-item class="inline-form mgl-15" style="width: 120px"
                        :prop="'room_ips[' + index + '].netPort'"
                        :rules="{ required: true, message: '网口不能为空', trigger: ['blur', 'change'] }">
                            <el-select v-model="config.netPort" placeholder="请选择" clearable size="small">
                                <el-option v-for="(item, index) in netPortList" :key="index" :value="item" :label="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <span class="mgl-8">*</span>
                        <el-form-item class="inline-form mgl-8" style="width: 100px"
                        :prop="'room_ips[' + index + '].num'"
                        :rules="{ validator: validateNum, trigger: ['blur', 'change'] }">
                            <el-input v-model="config.num" placeholder="请输入" clearable size="small"></el-input>
                        </el-form-item>
                        <el-form-item class="inline-form mgl-15">
                        <i class="el-icon-error oprate-btn" v-if="form.room_ips.length > 1" @click="deleteBtn(index)"></i>
                        </el-form-item>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-form-item class="click">
            <el-button class="colorBtn" @click="closeDialog()">取消</el-button>
            <el-button type="primary" @click="submit('form')">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import CountryProCity from "/src/utils/country-province-city.js";
import chinaCityCode from "/src/utils/china-city-code.js";
import room from "/src/api/room.js";

export default {
    name: 'RoomForm',
    props: {
        isAuth: {
            type: [Boolean],
            default: false,
        },
        isEdite: {
            type: [Boolean],
            default: false,
        },
        manufacturerId: {
            type: Number,
            default: null,
        }
    },
  data() {
    var validateIP = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('IP 地址不能为空'))
        }
        if (value && !(/((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/).test(value)) {
            callback(new Error('IP 地址不规范'))
        } else {
            callback()
        }
    };
    var validateNum = (rule, value, callback) => {
      if (/^\d+(\.\d+)?$/.test(value) == false) {
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    return {
      validateIP: validateIP,
      validateNum: validateNum,
      countryFormList: [],
      provincesFormList: [],
      cityFormList: [],
      ispsFormList: [],
      netPortList: ["千兆", "万兆", "25G"],
      form: {
        price: 20,
        room_ips: [
          {
            ip: "",
            netPort: "",
            num: "",
          },
        ],
      },
      formLabelWidth: '80px',
      timer: null,
      rules: {
        bandwidth: [{ required: true, validator: validateNum, trigger: ['blur', 'change'] }],
        region: [{ required: true, message: "国家/地区不能为空", trigger: ['blur', 'change'] }],
        name: [
            { required: true, message: "机房名称不能为空", trigger: ['blur', 'change'] },
            { min: 3, max: 20, message: "机房名字符长度不正确（3 - 20）", trigger: ['blur', 'change'] }
        ],
        cabinet: [
            { required: true, message: "机柜不能为空", trigger: ['blur', 'change']},
            { min: 3, max: 20, message: "机柜字符长度不正确（3 - 20）", trigger: ['blur', 'change']}
        ],
        address: [
            { required: true, message: "地址不能为空", trigger: ['blur', 'change'] },
            { min: 1, max: 50, message: "地址字符长度不正确（1 - 50）", trigger: ['blur', 'change']}
        ],
        isp: [{ required: true, message: "ISP 不能为空", trigger: ['blur', 'change'] }],
        price: [
            { required: true, message: "单价不能为空", trigger: ['blur', 'change'] },
            { type: 'number', message: "单价必须为数字", trigger: ['blur', 'change'] }
        ],
        remark: [{min: 3, max: 20, message: "备注字符长度不正确（3 - 20）", trigger: ['blur', 'change']}]
      },
    }
  },
  watch: {
    'form.region': {
      handler(newVal) {
        if (newVal === '') {
            this.form.province = ''
        }
        this.provincesFormList = [];
        this.cityFormList = [];
        if (newVal === "中国") {
            this.provincesFormList = Object.keys(this.cpcList[newVal].provinces);
        }
      },
      immediate: true,
    },
    'form.province': {
      handler(newVal) {
        if (newVal === '') {
            this.form.city = ''
        }
        this.cityFormList = [];
        if (newVal) {
            this.cityFormList = chinaCityCode.chinaCityCode;
            this.cityFormList.forEach((item) => {
                if (newVal === item.label) {
                    this.cityFormList = item.children;
                }
            });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$EventBus.$on('updateRowInfo', (data) => {
        if (data) {
            this.form = Object.assign({}, this.form, data)
            return
        }
    });
    this.getFormIdcSelect();
  },
  methods: {
    closeDialog() {
        this.$EventBus.$emit('updateCreateDialog', false);
        this.$refs['form'].clearValidate();
        this.$refs['form'].resetFields();
    },
    getFormIdcSelect() {
      // 获取创建机房国家的select
      const json = JSON.parse(JSON.stringify(CountryProCity));
      this.cpcList = json[0].countries;
      this.ispsFormList = json[0].isps;
      this.countryFormList = Object.keys(this.cpcList);
    },
    addBtn() {
      this.form.room_ips.push({
        ip: "",
        netPort: "",
        num: "",
      });
    },
    deleteBtn(index) {
      if (this.form.room_ips.length <= 1) return;
      this.form.room_ips.splice(index, 1);
    },
    submit(form) {
        this.$refs[form].validate((valid) => {
            if (valid) {
                if (!this.isEdite) { // 创建
                    this.form.manufacturer_id = this.manufacturerId;
                    room.createRoom(this.form).then(() => {
                        this.$EventBus.$emit('updateRoom', true);
                        this.$message("创建成功！");
                        this.closeDialog();
                    }).catch(({data}) => {
                        this.$message.error(data.message);
                    });
                } else { // 编辑
                    room.editRoom(this.form, this.form.id).then(() => {
                        this.$EventBus.$emit('updateRoom', true);
                        this.$message("修改成功！");
                        this.closeDialog();
                    }).catch(({data}) => {
                        this.$message.error(data.message);
                    });
                }
            } else {
                return false
            }
        })
    },
  }
}
</script>
