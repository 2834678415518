<style lang="scss" scoped>
.drawer-content {
    padding: 0 20px 0px
}
.tab-content {
    padding-top: 20px
}
.drawer-footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    padding: 0 20px 0px
}
.btn {
    display: flex;
    justify-content: flex-end;
}
</style>
<template>
    <div class="create-drawer">
        <el-drawer
        :title="!isEdite ? '新增机房' : '编辑机房'"
        :before-close="closeDialog"
        :visible.sync="createDialog"
        :wrapperClosable="false"
        direction="btt"
        custom-class="demo-drawer"
        ref="drawer"
        size="50%"
        >
        <div class="drawer-content">
            <RoomForm :isAuth="isAuth" v-if="!isEdite" :manufacturerId="manufacturerId" />
            <el-tabs v-model="activeTab" v-else>
                <el-tab-pane label="基本信息" name="info" class="tab-content">
                    <RoomForm :isAuth="isAuth" :isEdite="isEdite" />
                </el-tab-pane>
                <el-tab-pane label="单价" name="price" class="tab-content">
                    <div class="btn">
                        <el-button type="primary" @click="priceDialog = true" v-if="isAuth">新增</el-button>
                    </div>
                    <RoomInfo :isAuth="isAuth"/>
                </el-tab-pane>
            </el-tabs>
        </div>
        </el-drawer>
        <PriceModal :priceDialog.sync="priceDialog" />
    </div>
</template>
<script>
import RoomForm from "./RoomForm";
import RoomInfo from "./RoomInfo";
import PriceModal from "./PriceModal";

export default {
    name: 'CreateModal',
    components: { RoomForm, RoomInfo, PriceModal },
    props: {
        createDialog: {
            type: [Boolean],
            default: false,
        },
        isAuth: {
            type: [Boolean],
            default: false,
        },
        isEdite: {
            type: [Boolean],
            default: false,
        },
        manufacturerId: {
            type: Number,
            default: null
        },
    },
  data() {
    return {
        priceDialog: false,
        activeTab: 'info',
    };
  },
  watch: {
    createDialog() {
        this.activeTab = 'info'
    }
  },
  mounted() {
    this.$EventBus.$on('updatePriceDialog', () => {
        this.priceDialog = true
    });
  },
  methods: {
    closeDialog() {
        this.$emit('update:createDialog', false);
    },
    submit() {

    }
  }
}
</script>
