<style lang="scss" scoped>
</style>
<template>
    <div class="price-dialog">
        <el-dialog title="单价" :visible.sync="priceDialog" width="30%" :close-on-click-modal="false" :show-close=false>
            <el-form :model="form" :rules="rules" ref="priceForm" label-width="80px" label-position="left">
                <el-form-item label="机房" >
                    {{roomName}}
                </el-form-item>
                <el-form-item label="单价" prop="price">
                    <el-input v-model.number="form.price">
                        <template slot="append">元/Mbps/日</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="生效时间" prop="startTime">
                    <el-date-picker
                        v-model="form.startTime"
                        type="date"
                        placeholder="选择生效日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog()">取 消</el-button>
                <el-button type="primary" @click="submit('priceForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script lang="js">
import room from "/src/api/room.js";
import moment from "moment";
export default {
    name: 'PriceModal',
    props: {
        priceDialog: {
            type: [Boolean],
            default: false,
        },
    },
  data() {
    return {
        isEditePrice: false,
        roomName: '',
        form: {},
        rules: {
            price: [
                { required: true, message: '请输入单价', trigger: 'blur' },
                { type: 'number', message: "单价必须为数字", trigger: 'blur' },
            ],
            startTime: [
                { required: true, message: '请选择生效日期', trigger: 'blur' }
            ]
        }
    };
  },
  watch: {
  },
  mounted() {
    this.$EventBus.$on('updateRowInfo', (data) => {
        this.form.id = data.id
        this.roomName = data.name
    });
    this.$EventBus.$on('updatePriceDialog', (data) => {
        this.isEditePrice = true;
        this.form = JSON.parse(JSON.stringify(data))
    });
  },
  methods: {
    closeDialog() {
        this.$emit('update:priceDialog', false);
        this.$refs['priceForm'].resetFields();
        this.$refs['priceForm'].clearValidate();
        this.form = {
            id: this.form.id,
        }
        this.isEditePrice = false;
    },
    submit(formName) {
        const params = {
            id: this.form.id,
            price: parseInt(this.form.price),
            startTime: moment(this.form.startTime).toISOString(),
            remark: this.form.remark,
        }
        this.$refs[formName].validate((valid) => {
            if (valid) {
                if (!this.isEditePrice) { // 创建
                    room.createPrice(params, params.id).then(() => {
                        this.$EventBus.$emit('updateRowInfo', {id: params.id, name: this.roomName});
                        this.$message("创建成功！");
                        this.closeDialog();
                    }).catch(({data}) => {
                        this.$message.error(data.message);
                    });
                } else { // 编辑
                    room.editPrice(params, this.form.id).then(() => {
                        this.$EventBus.$emit('updateRowInfo', {id: this.form.roomId, name: this.roomName});
                        this.$message("修改成功！");
                        this.closeDialog();
                    }).catch(({data}) => {
                        this.$message.error(data.message);
                    });
                }
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    }
  }
}
</script>
